export default [
  {
    header: 'User Interface',
  },
  {
    title: 'Typography',
    route: 'ui-typography',
    icon: 'TypeIcon',
  },
  {
    title: 'Colors',
    route: 'ui-colors',
    icon: 'DropletIcon',
  },
  {
    title: 'Icons',
    route: 'ui-icons',
    icon: 'GridIcon',
  },
  {
    title: 'Download',
    route: 'download',
    icon: 'DownloadIcon',
  }
]
