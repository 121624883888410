export default [
  {
    header: 'Brand guideline',
  },
  {
    title: 'Introduction',
    route: 'about',
    icon: 'HomeIcon',
  },
  {
    title: 'Guideline',
    route: 'guideline',
    icon: 'FileTextIcon',
  }
]
